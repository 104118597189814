<template>
  <div id="container">
      <router-link to="/welcome"></router-link> 
    <router-view/>
  </div>
</template>

<script>
export default {
  data(){
    return {
      
    }
  },
  
  
}
</script>

<style lang="less">
*{
  margin: 0;
  padding: 0;
}
html, body, #container{
  height:100%
}
html{
  font-size: 26.6666vw;
}
@media all and (min-width:1920px){
  html{
    font-size:100px
  }
} 
body{
  font-size: 14px;
}
#container{
  overflow:auto;
  .box{
    width: 100%;
  }
}
</style>
